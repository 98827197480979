<script setup>
import { useAppStore } from '@/stores/App'
import { useCartStore } from '@/stores/Cart'
import { useTheme } from 'vuetify'

const theme = useTheme()
const app = useAppStore()
const cart = useCartStore()

function toggleTheme() {
    // console.log(theme.global.name.value)
    // console.log(theme.global.current.value)
    // theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    if (theme.global.name.value == 'sTDarkTheme') {
        theme.global.name.value = 'sTLightTheme'
    } else {
        theme.global.name.value = 'sTDarkTheme'
    }
}
</script>

<template>
    <v-app-bar id="thenavbar" scroll-behavior="elevate" scroll-threshold="100">
        <v-app-bar-nav-icon class="d-flex d-sm-none" @click.stop="app.navDrawer = !app.navDrawer"> </v-app-bar-nav-icon>

        <v-spacer class="d-flex d-sm-none"></v-spacer>

        <v-btn to="/" style="min-width: 36px !important; padding: 0 !important" aria-label="Visit satYatunes Home Page">
            <v-avatar tile class="rounded">
                <v-img alt="satyatunes-logo" src="/sT-logo.jpg"></v-img>
            </v-avatar>
        </v-btn>

        <v-spacer class="d-none d-sm-none"></v-spacer>

        <!-- div is needed to club all button together at center -->
        <div class="d-none d-sm-flex">
            <v-btn
                v-for="item in app.navbarItems"
                :key="item.title"
                :text="item.title"
                :to="item.route"
                :aria-label="`Visit ${item.title}`"
                variant="text"
            ></v-btn>
        </div>

        <v-spacer class="d-sm-none"></v-spacer>
        <div>
            <v-tooltip text="Change Theme">
                <template v-slot:activator="{ props }">
                    <v-btn
                        v-bind="props"
                        :icon="
                            theme.global.name.value == 'sTLightTheme' ? 'mdi-weather-night' : 'mdi-white-balance-sunny'
                        "
                        @click.stop="toggleTheme"
                    ></v-btn>
                </template>
            </v-tooltip>
            <v-btn class="text-none" stacked aria-label="View Cart">
                <v-badge
                    v-if="cart.totalItems > 0"
                    color="secondary"
                    :content="!cart.totalItems ? '0' : cart.totalItems"
                >
                    <v-icon
                        icon="mdi-shopping"
                        color="light-blue-accent-3"
                        size="large"
                        @click.stop="cart.drawer = !cart.drawer"
                        aria-label="View Cart"
                    ></v-icon>
                </v-badge>
                <!-- <v-badge v-else color="" content="0"> -->
                <v-icon
                    v-else
                    icon="mdi-shopping-outline"
                    size="large"
                    @click.stop="cart.drawer = !cart.drawer"
                    aria-label="View Cart"
                ></v-icon>
                <!-- </v-badge> -->
            </v-btn>
        </div>
    </v-app-bar>
</template>

<style scoped>
#thenavbar a.v-btn.v-btn--active.v-theme--sTDarkTheme {
    background-color: rgba(0, 0, 0, 0.9) !important;
    text-decoration: underline !important;
    text-underline-offset: 6px !important;
}
#thenavbar a.v-btn.v-btn--active.v-theme--sTLightTheme {
    background-color: rgba(255, 255, 255, 0) !important;
    /* text-decoration: underline !important;
    text-underline-offset: 6px !important; */
}
</style>
