<script setup>
import { useCartStore } from '@/stores/Cart'
const cart = useCartStore()
</script>

<template>
    <v-navigation-drawer
        id="thecartdrawer"
        v-model="cart.drawer"
        location="right"
        mobile-breakpoint="xs"
        temporary
        width="320"
    >
        <v-row class="flex-column mx-1 my-1">
            <v-col class="pa-2 text-h6 font-weight-medium">Shopping Cart</v-col>
            <!-- <v-divider></v-divider> -->
            <v-col v-if="cart.items && cart.items.length > 0" class="d-flex flex-column justify-center">
                <cart-item />
                <app-button
                    class="d-flex justify-center mt-2"
                    :to="`/cart`"
                    :btn-text="`View Cart`"
                    @click.stop="cart.drawer = !cart.drawer"
                    aria-label="View cart"
                />
            </v-col>
            <v-col v-else>
                <v-row class="flex-column text-center">
                    <v-col class="text-h6 text-error font-weight-medium">Shopping cart is empty.</v-col>
                    <!-- <v-col class="font-weight-medium">Browse our shop and add product to cart.</v-col> -->
                    <v-col>
                        <v-divider></v-divider>
                    </v-col>
                    <v-col>
                        <app-button
                            :to="`/shop`"
                            :btn-text="`Continue Shopping`"
                            @click.stop="cart.drawer = !cart.drawer"
                            aria-label="Visit satYatunes shop"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>

<style scoped>
/*#thecartdrawer {
    top: 0px !important;
    height: 100% !important;
    z-index: 1100 !important;
}*/
</style>
