<script setup>
import { onMounted, ref } from 'vue'

const gdprCookie = useCookie('stGdpr')

const showBanner = ref(false)

onMounted(() => {
    // If no cookie then display banner window['gdprCookie']
    // console.log(gdprCookie)
    if (gdprCookie.value === undefined) {
        showBanner.value = true
    }
})

// Set cookie and close banner
function setCookie() {
    gdprCookie.value = true
    showBanner.value = false
}
</script>

<template>
    <v-bottom-sheet v-model="showBanner" inset persistent>
        <v-sheet class="pa-4 mx-auto rounded">
            <v-row>
                <v-col class="text-center">
                    <p>
                        We use cookies to improve user experience, analyze website traffic, and to tailor advertising.
                        By continuing to use our website, you accept our use of cookies as described in our
                        <NuxtLink
                            to="/cookie"
                            class="st-link font-weight-bold text-decoration-underline"
                            aria-label="satYatunes cookie policy"
                        >
                            Cookie Policy
                        </NuxtLink>
                    </p>
                    <v-btn
                        variant="flat"
                        color="secondary"
                        class="my-2"
                        @click="setCookie"
                        aria-label="Accept cookie policy"
                    >
                        Ok, Got it.
                    </v-btn>
                    <p>You can change your cookie settings at any time in your Browser settings.</p>
                </v-col>
            </v-row>
        </v-sheet>
    </v-bottom-sheet>
</template>

<style scoped></style>
