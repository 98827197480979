<script setup>
import { useAppStore } from '@/stores/App'
const app = useAppStore()
</script>

<template>
    <v-row id="thefooter" class="the-footer justify-center">
        <v-col cols="12" sm="10">
            <v-row class="py-8">
                <v-col cols="12" sm="6" md="3" lg="6">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center justify-sm-start text-h4 font-weight-bold">
                            <!-- <v-avatar tile size="32" class="mt-1 mr-1">
                                    <v-img src="/sT-logo-dark-nohex-blue_50x50.jpg" alt="satYatunes-logo"></v-img>
                                </v-avatar> -->
                            satYatunes
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center justify-sm-start">
                        <v-col
                            v-for="snLink in app.socNetworkLinks"
                            :key="snLink.title"
                            cols="2"
                            md="3"
                            lg="2"
                            class="text-center text-md-left"
                        >
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <nuxt-link
                                        v-bind="props"
                                        :to="snLink.route"
                                        target="_blank"
                                        rel="noopener"
                                        external
                                        class="text-decoration-none"
                                        :aria-label="`Visit ${snLink.title}`"
                                    >
                                        <v-icon
                                            size="x-large"
                                            :color="isHovering ? `${snLink.hoverColor}` : 'grey-lighten-4'"
                                            :class="isHovering ? 'zoom-icon-on-hover' : ''"
                                        >
                                            {{ snLink.icon }}
                                        </v-icon>
                                    </nuxt-link>
                                </template>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    v-for="ftrLink in app.footerLinks"
                    :key="ftrLink.title"
                    cols="12"
                    sm="6"
                    md="3"
                    lg="2"
                    class="pt-6 px-6"
                >
                    <v-row>
                        <v-col class="d-flex justify-center justify-sm-start font-weight-bold">
                            {{ ftrLink.title }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-for="(link, i) in ftrLink.links"
                            :key="i"
                            cols="12"
                            class="pt-0 pb-1 d-flex justify-center justify-sm-start"
                        >
                            <nuxt-link :to="link.route" class="the-footer-links" :aria-label="`Visit ${link.title}`">
                                {{ link.title }}
                            </nuxt-link>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>
.zoom-icon-on-hover {
    animation: iconZoomOnHover 1s linear;
}

@keyframes iconZoomOnHover {
    from {
        transform: scale(1.3, 1.3);
    }
    to {
        transform: none;
    }
}
</style>
