<script setup>
const curYear = new Date().getFullYear()
</script>

<template>
    <v-row id="thebottom" class="the-bottom justify-center rounded-b">
        <v-col cols="12">
            <v-row class="pa-2">
                <v-col cols="12" md="8" class="d-flex align-center justify-center justify-md-start">
                    <v-icon size="16" color="grey-darken-1">mdi-copyright</v-icon> {{ curYear }}, satYatunes. All rights
                    reserved.
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-center justify-center justify-md-end">
                    Powered by&nbsp;
                    <nuxt-link to="/services" aria-label="Explore satYatunes services">
                        <v-avatar size="32">
                            <v-img src="/sT-logo.jpg" alt="satYatunes-logo"></v-img>
                        </v-avatar>
                    </nuxt-link>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped></style>
